import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./utils/Keycloak";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./helpers/PrivateRoute";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize("G-DNNPHZV5D9");
    return (
        <>
            <div>
                <ReactKeycloakProvider authClient={keycloak}
                                       initOptions={{
                                           onLoad: 'login-required',
                                           checkLoginIframe: false,
                                           enableLogging: false,
                                           pkceMethod: 'S256'
                                       }}
                                       autoRefreshToken>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path="/"
                                element={<PrivateRoute>
                                    <Nav/>
                                    <Dashboard/>
                                </PrivateRoute>}/>
                        </Routes>
                    </BrowserRouter>
                </ReactKeycloakProvider>
            </div>
        </>

    );
}

export default App;
