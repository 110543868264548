import Keycloak from "keycloak-js";
import AppConfig from "../AppConfig";
const keycloak = new Keycloak({
    url: "https://auth.7x.ax/auth",
    realm: "7x",
    clientId: AppConfig.oauth_client,
});

export default keycloak;


