import Keys from "./../components/Keys"
import { useKeycloak } from "@react-keycloak/web";

const Secured = () => {

    const { keycloak, initialized } = useKeycloak();


    return (
         <>
         <header className="bg-white shadow-sm">
             <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
                 <h1 className="text-lg font-semibold leading-6 text-gray-900">Dashboard</h1>
             </div>
         </header>
        <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <div>
                    <h3 className="text-3xl font-bold tracking-tight text-gray-900 md:text-3xl">Welcome, {keycloak.tokenParsed.given_name}.</h3>
                    <Keys />
                </div>
            </div>
        </main>
       </>
    );
};

export default Secured;