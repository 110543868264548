export default function Loader() {
    return (
        <>
            <div className="flex flex-col items-center justify-center mt-20">
                <div className="absolute animate-spin rounded-full h-60 w-60 border-t-2 border-b-2 border-red-600 m-20"></div>
                <img src="7x.svg" className="animate-pulse rounded-full h-80 w-100" alt="7x Logo" />
            </div>
        </>
    );

}